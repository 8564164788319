import { REGEXP_ONLY_DIGITS_AND_CHARS, type OTPInputProps } from "input-otp"
import React, { useId } from "react"
import { cn } from "#app/utils/misc.tsx"
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "./ui/input-otp.tsx"

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({ id, errors }: { errors?: ListOfErrors; id?: string }) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-sm text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

interface TextFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
	type?: "text" | "password" | "email" | "tel" | "url" | "search" | "number"
	label: React.ReactNode
	errors?: ListOfErrors
}

export function TextField({ label, errors, className, ...inputProps }: TextFieldProps) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn("space-y-1", className)}>
			{label ? (
				<label htmlFor={id} className="field-label">
					{label}
				</label>
			) : null}
			<input
				id={id}
				type="text"
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				className="text-field"
				{...inputProps}
			/>
			<div className="min-h-[32px] px-3 pb-2 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function OTPField({
	label,
	errors,
	className,
	...inputProps
}: {
	label: React.ReactNode
	errors?: ListOfErrors
} & Partial<OTPInputProps & { render: never }>) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn("space-y-1", className)}>
			<label htmlFor={id} className="field-label">
				{label}
			</label>
			<InputOTP
				pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
				maxLength={6}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
				</InputOTPGroup>
				<InputOTPSeparator />
				<InputOTPGroup>
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

interface TextareaFieldProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	label: React.ReactNode
	errors?: ListOfErrors
}

export function TextareaField({ label, errors, className, ...textareaProps }: TextareaFieldProps) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn("space-y-1", className)}>
			<label htmlFor={id} className="field-label">
				{label}
			</label>
			<textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				className="textarea-field"
				{...textareaProps}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

interface CheckboxFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
	label: React.ReactNode
	errors?: ListOfErrors
}

export function CheckboxField({ label, errors, className, ...inputProps }: CheckboxFieldProps) {
	const { defaultChecked, ...checkboxProps } = inputProps
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2">
				<input
					type="checkbox"
					className="checkbox-field"
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
				/>
				<label htmlFor={id} className="self-center text-body-xs text-muted-foreground">
					{label}
				</label>
			</div>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}
